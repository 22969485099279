import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "./link";
import Arrow from "./arrow";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = node.data.target;

      if (image) {
        return <GatsbyImage image={image.gatsbyImageData} alt={image.title} />;
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },
};

const Accordion = ({ id, title, subheading, category, content }) => {
  const [accordion, setAccordion] = React.useState(null);
  const accordionEl = React.useRef(null);
  const delay = accordion ? 10 : 200;

  const handleClick = () => {
    setAccordion(!accordion ? true : null);
    accordionEl.current.style = `--height: ${accordionEl.current.scrollHeight}px`;

    setTimeout(() => {
      accordionEl.current.removeAttribute("style");
    }, delay);
  };

  if (content) {
    return (
      <article className="accordion">
        <header>
          <button
            onClick={handleClick}
            aria-expanded={accordion || false}
            aria-controls={id}
          >
            <span className="visually-hidden">{title}</span>
          </button>
          <div>
            <h3 aria-hidden>{title}</h3>
            {subheading && <p>{category.title}</p>}
          </div>
          <Arrow accordion />
        </header>
        <div
          ref={accordionEl}
          id={id}
          aria-label={title}
          hidden={!accordion || null}
        >
          <div>{renderRichText(content, renderOptions)}</div>
        </div>
      </article>
    );
  }

  return null;
};

export default Accordion;
