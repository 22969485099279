import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import slugify from "slugify";
import Accordion from "./accordion";
import ButtonLink from "./button-link";

const slugOptions = {
  lower: true,
  strict: true,
  trim: true,
};

const query = graphql`
  query {
    allContentfulLink(
      filter: { metadata: { tags: { elemMatch: { name: { eq: "faq" } } } } }
    ) {
      nodes {
        node_locale
        id
        button
        buttonBackgroundColour
        target
        textColour
        title
        link
      }
    }
  }
`;

const Question = ({ questions, heading, product }) => {
  const { allContentfulLink } = useStaticQuery(query);
  const { locale } = useIntl();
  const slug = heading && slugify(heading, slugOptions);

  const questionButton = allContentfulLink.nodes.find(
    (item) => item.node_locale === locale
  );

  if (questions) {
    return (
      <section
        id={heading ? slug : "questions"}
        className={`questions${product ? " questions-product" : ""}`}
        aria-labelledby={heading ? slug : "questions-heading"}
      >
        <div className="container">
          <header>
            <h2 id={heading ? slug : "questions-heading"} className="heading">
              {heading || <FormattedMessage id="questionsHeading" />}
            </h2>
            {!heading && questionButton && (
              <ButtonLink
                href={questionButton.link}
                backgroundColor={questionButton.buttonBackgroundColour}
                textColor={questionButton.textColour}
                button={questionButton.button}
                target={questionButton.target}
              >
                {questionButton.title}
              </ButtonLink>
            )}
          </header>
          {questions.map((question) => (
            <Accordion
              key={question.contentful_id}
              id={question.contentful_id}
              title={question.title}
              subheading={!heading}
              category={question.category}
              content={question.content}
            />
          ))}
        </div>
      </section>
    );
  }

  return null;
};

export default Question;
