import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Banner from "../components/banner";
import Presentation from "../components/presentation";
import Products from "../components/products";
import Questions from "../components/questions";
import Slideshow from "../components/slideshow";
import Articles from "../components/articles";
import ImageWithText from "../components/image-with-text";

const DestinationTemplate = ({ data }) => {
  const {
    title,
    seo,
    mainTitle,
    products,
    intro,
    badge,
    featuredImage,
    banner,
    questions,
    quotationsTitle,
    quotations,
    articles,
    seoSection,
    noIndex,
    hideProducts,
    hideBadge,
  } = data.destination;

  const seoTitle = seo?.title || title;
  const seoDescription = seo?.description?.description || intro?.intro;
  const seoImage = seo?.image || featuredImage;
  const canonicalURL = seo?.canonicalURL;

  return (
    <Layout whiteHeader={featuredImage}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        canonicalURL={canonicalURL}
        noIndex={noIndex}
      />
      <Hero
        title={title}
        intro={intro?.intro}
        media={featuredImage}
        pageTitle={false}
        reviews={true}
      />
      <Banner
        heading={banner?.heading}
        text={banner?.text}
        link={banner?.link}
        linkText={banner?.linkText}
        active={banner?.active}
        buttonLink={banner?.buttonLink}
      />
      <Presentation destination={data.destination} title={mainTitle} />
      {!hideProducts && (
        <Products products={products} badge={badge} hideBadge={hideBadge} />
      )}
      <Questions questions={questions} />
      <Slideshow
        slides={quotations}
        title={quotationsTitle?.quotationsTitle}
        heading
      />
      <Articles articles={articles} heading />
      <ImageWithText
        image={seoSection?.image}
        mobileImage={seoSection?.mobileImage}
        imageAlt={seoSection?.imageAlt}
        text={seoSection?.text}
      />
    </Layout>
  );
};

export default DestinationTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    destination: contentfulDestination(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      title
      slug
      mainTitle
      hideProducts
      hideBadge
      noIndex
      seo {
        canonicalURL
        title
        description {
          description
        }
        image {
          description
          title
          gatsbyImageData(
            width: 1200
            height: 1200
            quality: 80
            resizingBehavior: FILL
          )
        }
      }
      category {
        title
      }
      dateRange
      products: product {
        contentful_id
        destination {
          slug
        }
        level {
          title
          slug
          color
          order
        }
        featuredImage {
          description
          gatsbyImageData(quality: 80)
          title
        }
        intro {
          intro
        }
        duration
        distance
        elevation
        difficulty
        price
      }
      intro {
        intro
      }
      featuredImage {
        description
        gatsbyImageData(quality: 80)
        title
        file {
          details {
            image {
              width
              height
            }
          }
        }
      }
      badge {
        description
        title
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      banner {
        heading
        text {
          raw
        }
        link
        linkText
        active
        buttonLink {
          id
          button
          buttonBackgroundColour
          textColour
          title
          link
          target
        }
      }
      presentation {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            gatsbyImageData(quality: 80)
          }
        }
      }
      questions {
        contentful_id
        title
        category {
          title
        }
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              description
              gatsbyImageData(quality: 80)
            }
          }
        }
      }
      quotationsTitle {
        quotationsTitle
      }
      quotations {
        contentful_id
        image {
          description
          title
          gatsbyImageData(quality: 80)
        }
        mobileImage {
          title
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
        link
      }
      articles {
        contentful_id
        publishedAt
        title
        slug
        featuredImage {
          description
          gatsbyImageData(quality: 80)
          title
        }
        publishedAt
        excerpt {
          excerpt
        }
      }
      seoSection {
        image {
          description
          title
          gatsbyImageData(quality: 80)
        }
        mobileImage {
          title
          gatsbyImageData(quality: 80)
        }
        imageAlt
        text {
          raw
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
